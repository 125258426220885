import React, { useContext, useState, useEffect } from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import { Box, Heading, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { getSrc } from 'gatsby-plugin-image'
import { FiArrowRight } from '@react-icons/all-files/fi/FiArrowRight'
import { FiArrowLeft } from '@react-icons/all-files/fi/FiArrowLeft'
import { CarouselContext, ButtonNext, ButtonBack } from 'pure-react-carousel'
import { EditionCoverStyle, CommonStyle } from '../../utilities/styles'

function MyEditionCover({ data, edition, index, slides }) {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext?.state?.currentSlide);

  const isLastSlide = index === slides.length-1

  const backgroundImage = `
    linear-gradient(0deg, rgba(0, 0, 0, 0.6), 
    rgba(0, 0, 0, 0.6)), 
    url(${getSrc(edition.editionAdditionalFields.coverImage?.image?.localFile)}),
    no-repeat,
    center
  `

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  useEffect(() => {
    if (slides[currentSlide] && window)
      window.history.pushState(null, null, `/${slides[currentSlide]?.slug}`)
  }, [currentSlide]);

  return (
    <Box
      p={['0', '0', '10']}
      position="relative"
      flexDirection="column"
      justifyContent={['none', 'none', 'space-between']}
      minHeight="100vh"
      background={[
      'unset',
      'unset',
      backgroundImage]}
      backgroundSize={['unset', 'unset', 'cover']}>
      <Flex 
        minHeight={['256px', '256px', 'unset']}
        alignItems="flex-end"
        background={[
          backgroundImage,
          backgroundImage,
          'unset',
        ]}
        backgroundSize={['cover', 'cover', 'unset']}
        px={['4', '4','0']}
        py={['8', '8','0']}>
        <Heading 
          as="h1" 
          m="0" 
          size="4xl" 
          color="white"
          display={['none', 'none', 'block']}>
          {data.site.siteMetadata.title}
        </Heading>
        <Heading 
          as="h1" 
          m="0" 
          size="2xl" 
          color="white"
          display={['block', 'block','none']}>
          {data.site.siteMetadata.title}
        </Heading>
      </Flex>
      <Flex 
        position={['unset', 'absolute']} 
        left="10"
        right="10"
        bottom="10" 
        flexDirection={['column', 'column', 'row']} px={['4', 4, '0']}>
        <Grid templateColumns="repeat(2, 1fr)" color="white" flex="1">
          <GridItem 
            py={[8, 4]}
            borderTop={['none', 'none', '1px solid']} 
            borderBottom={['1px solid', '1px solid', 'none']} >
            <Link to={`/${edition.slug}/cover`}>
              <Heading 
                display={['none', 'none', 'block']} 
                m="0" 
                as="h4" 
                size="lg">
                {edition.title} Issue
              </Heading>
              <Heading 
                display={['block', 'block', 'none']} 
                m="0" 
                as="h4" 
                size="sm">
                {edition.title} Issue
              </Heading>
            </Link>
          </GridItem>
          <GridItem 
            py={[8, 4]}
            borderTop={['none', 'none', '1px solid']} 
            borderBottom={['1px solid', '1px solid', 'none']}>
            <Heading 
              display={['none', 'none', 'block']} 
              m="0" 
              as="h4" 
              size="lg">
              {edition.editionAdditionalFields.mainStoryTitle}
            </Heading>
            <Heading 
              display={['block', 'block', 'none']} 
              m="0" 
              as="h4" 
              size="sm">
              {edition.editionAdditionalFields.mainStoryTitle}
            </Heading>
          </GridItem>
          <GridItem 
            py={[8, 4]}
            display={['none', 'block']}
            colSpan={[2, 2, 1]}
            borderTop={['none', 'none', '1px solid']} 
            borderBottom="1px solid">
            <Text>Director: {edition.editionAdditionalFields.mainAuthor}</Text>
            <Text>Photographer: {edition.editionAdditionalFields.coverImage.photographer}</Text>
            <Text>Year: {edition.editionAdditionalFields.coverImage.year}</Text>
          </GridItem>
          <GridItem 
            py={[8, 4]}
            colSpan={[2, 2, 1]}
            borderY={['none', 'none', '1px solid']}>
            {edition.editionAdditionalFields.mainStoryDescription}
          </GridItem>
          <GridItem 
            py={[8, 4]}
            display={['block', 'none']}
            colSpan={[2, 2, 1]}
            borderTop="1px solid"
            borderBottom="1px solid">
            <Text>Director: {edition.editionAdditionalFields.mainAuthor}</Text>
            <Text>Photographer: {edition.editionAdditionalFields.coverImage.photographer}</Text>
            <Text>Year: {edition.editionAdditionalFields.coverImage.year}</Text>
          </GridItem>
        </Grid>
        {slides.length > 1 && (
          <Box 
            display={['none', 'none', 'block']}
            border="1px solid" 
            textAlign="center" 
            ml="8"
            bg={EditionCoverStyle.ViewChapterButtonContainerBg()}>
            <Box borderBottom="1px solid" px="4" py="2">
              <Text fontWeight="bold">View Chapters</Text>
            </Box>
            <Box px="4" py="4">
              {isLastSlide ? 
                <ButtonBack id="button-back" style={CommonStyle.roundedButton}>
                  <FiArrowLeft fontSize="24" color={EditionCoverStyle.ArrowIcon}/>
                </ButtonBack> :
                <ButtonNext id="button-next" style={CommonStyle.roundedButton}>
                  <FiArrowRight fontSize="24" color={EditionCoverStyle.ArrowIcon}/>
                </ButtonNext>
              }
              <Text whiteSpace="nowrap" mt="2">NAVIGATE / DRAG</Text>
            </Box>
          </Box>
        )}
      </Flex>
      <Box display={['block', 'block', 'none']} py="75px">&nbsp;</Box>
    </Box>
 )
}

export default function EditionCover({ edition, index, slides }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <MyEditionCover 
          data={data} 
          index={index}
          slides={slides}
          edition={edition} />
      )}
    />  
  )
}