import React, { useState, useEffect } from "react"
import { Box } from "@chakra-ui/react"
import Layout from "../components/Layout"
import EditionCover from "../components/edition/EditionCover"
import { onKeydown } from '../utilities/event'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css'

const Home = ({ pageContext }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [allEditions, setAllEditions] = useState([])

  useEffect(() => {
    if (pageContext) {
      setAllEditions(pageContext.editions)
      const idx = pageContext.editions.findIndex(e => e.id === pageContext.id)
      idx >= 0 && setCurrentSlide(idx)
    }
  }, [pageContext])

  useEffect(() => {
    document.onkeydown = onKeydown
    return () => {
      document.onkeydown = null
    }
  }, [])

  return (
    <Box w="100%" h="100%">
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        currentSlide={currentSlide}
        totalSlides={allEditions.length}
        isIntrinsicHeight={true}
        dragStep={1}>
        <Slider>
          {allEditions.map((edition, index) => (
            <Slide key={edition.id} className="slide" style={{height: '100vh'}}>
              <EditionCover 
                index={index}
                slides={allEditions}
                edition={edition} />
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </Box>
  )
}

Home.Layout = Layout
export default Home